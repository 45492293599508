<template>
    <div>
        <header-menu></header-menu>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>

<script setup>
import headerMenu from '@/components/headerMenu.vue'
import * as echarts from 'echarts'
import { provide } from 'vue'
provide('ec', echarts)//provide
</script>

<style lang="scss">
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    touch-action: none;
    touch-action: pan-x;
    touch-action: pan-y;
}

html,
body,
div {
    margin: 0 auto;
    position: relative;
    width: 100%;

}

input {
    background: none;
    outline: none;
    border: 0;
    color: #363636;
}

input:focus {
    outline: none;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #A3A3A3;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #A3A3A3;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #A3A3A3;
}

button:focus {
    outline: none;
}

img {
    width: 100%;
    display: block;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    font-size: 1.2rem;

    .page {

        .page-title {
            padding: 3rem 2rem;
            line-height: 1.7rem;

            .return-button {
                position: absolute;
                width: 1.4rem;
                height: 1.4rem;
                z-index: 1;
            }

            .page-title-text {
                font-size: 1.7rem;
                text-align: center;
                color: #272727;
            }
        }
    }
}
</style>
