import request from '@/utils/request'

// 手机号登录
export function loginByMobileAPI(data) {
    return request({
        url: 'user/phone_login',
        method: 'post',
        data,
    }).then((res) => {
        return res
    })
}

export function upUser(data) {
    return request({
        url: 'user/upuser_info',
        method: 'post',
        data,
    })
}
