import { defineStore } from 'pinia'
import { loginByMobileAPI } from "@/api/login";

import {
    getToken,
    setToken,
    removeToken
} from '@/utils/localStorage'

export const useUserStore = defineStore({
    id: 'user',
    state: () => {
        return {
            userInfo: {
                mobile: '',
            },
            session_id: getToken(),
        }
    },
    // 相当于 vue 中的 computed 计算属性
    getters: {
        getToken: (state) => state.session_id,
        getUser: (state) => state.user,
    },
    // 相当于 vue 中的 methods 方法
    actions: {
        // 短信登陆
        async loginByMobile(formData) {
            const { data } = await loginByMobileAPI(formData)
            if (data.status == 100) {
                this.session_id = data.data.session_id
                setToken(this.session_id)
            }
            return data
        },
        async logout() {
            let data = {
                status: 100,
                message: '已退出登录',
            }
            return data
        },
        removeToken() {
            console.log('进入userStore')
            removeToken()
        },
    }
})
