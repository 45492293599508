import { tokenTableName } from '@/config'

/**
 * @description 获取token
 */
export function getToken() {
    return localStorage.getItem(tokenTableName)
}

/**
 * @description 存储token
 * @param session_id
 */

export function setToken(session_id) {
    return localStorage.setItem(tokenTableName, session_id)
}

/**
 * @description 移除token
 */
export function removeToken() {
    return localStorage.removeItem(tokenTableName)
}