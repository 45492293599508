/**
 *  导出通用配置
 */
module.exports = {
    // token名称
    tokenName: 'sessionId',
    // 开发和测试环境api地址

    apiUrlForDev: 'https://h5api.ypoffer.com/',
    // 生产环境api地址  https://h5api.ypoffer.com/
    apiUrlForPro: 'https://h5api.ypoffer.com/',
    // 问号后边代表开发环境，冒号后边代表生产环境
    baseURL: process.env.NODE_ENV === 'development' ? 'https://test.resume.api.ypoffer.com' : 'https://api.ypoffer.com/',
    // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
}