import axios from "axios"; // 引用axios
import { useUserStore } from '@/store/modules/user' // pinia 状态
import { apiUrlForDev, apiUrlForPro } from '@/config' // 打包配置
import { result } from './response'  // 状态码返回对应的操作封装


let baseURL = ''  // 域名地址
if (process.env.NODE_ENV === 'development') {
    baseURL = apiUrlForDev // 开发环境
} else if (process.env.NODE_ENV === 'production') {
    baseURL = apiUrlForPro   //  生产环境
} else if (process.env.NODE_ENV === 'test') {
    baseURL = apiUrlForDev    // 测试环境
}

// 创建请求
const request = axios.create({
    baseURL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
})

// 请求设置 
const requestFun = config => {
    const { session_id } = useUserStore()
    config.data['sessionId'] = session_id
    return config
}

// 响应设置
const responseFun = config => {
    const { removeToken } = useUserStore()
    if (config.data.status == 201) {
        removeToken()
    }
    return config
}
// 请求拦截器
request.interceptors.request.use(requestFun, (error) => { result(error) });
// 响应拦截器
request.interceptors.response.use(responseFun, (error) => { result(error) });

export default request;

