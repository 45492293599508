<template>
    <div class="header-menu">
        <img v-if="route.meta.title != '首页'" style="width:16px;margin-left: 15px;" src="@/assets/img/back.png" alt=""
            @click="goBack">
        <div v-else style="width:16px;margin-left: 15px;"></div>
        <div class="title">{{ route.meta.title }}</div>
        <img style="width:26px;margin-right: 15px;" src="@/assets/img/my.png" alt="" @click="goMy">
    </div>
</template>

<script>
export default {
    name: 'headerMenu',
}
</script>

<script setup>
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
const goBack = () => {
    router.back(-1)
}
const goMy = () => {
    router.push({ path: '/my' })
}
</script>

<style lang="scss" scoped>
.header-menu {
    position: sticky;
    top: 0;
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f1f1f1;
    z-index: 9999;

    .back {
        position: absolute;
        left: 10px;
        width: auto;
        z-index: 100;
        color: #7c7c7c;
    }

    .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }
}
</style>
