import router from '@/router';
import { Toast } from '@nutui/nutui'

const result = function(data) {
    switch(data.status) {
        case 0:
            Toast.fail(
                data.message,
                {
                    cover: true
                },
            )
            break
        case 403:
            Toast.warn(
                data.message,
                {
                    cover: true
                },
            )
            break
        case 405:
            Toast.warn(
                data.message,
                {
                    cover: true
                },
            )
            router.push('/login')
            break
        default:
            Toast.fail(
                data.status + '错误: ' + data.message,
                {
                    cover: true
                },
            )
            break
    }
}

export default result