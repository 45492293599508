import { createRouter, createWebHistory } from 'vue-router'
import pinia from '@/store'
import { useUserStore } from '@/store/modules/user'

const routes = [
    {
        path: '/',
        name: 'homeIndex',
        meta: {
            title: '首页',
            keepAlive: false,
            auth: false,
        },
        component: () => import('@/views/home/index.vue'),
    },
    {
        path: '/login',
        name: 'loginIndex',
        meta: {
            title: '登录/注册',
            keepAlive: false,
            auth: false,
        },
        component: () => import('@/views/login/index.vue'),
    },
    {
        path: '/my',
        name: 'myIndex',
        meta: {
            title: '个人中心',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/my/index.vue'),
    },
    {
        path: '/info',
        name: 'myInfo',
        meta: {
            title: '个人资料',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/my/info.vue'),
    },
    {
        name: "AIEdit",
        path: '/aiEdit',
        meta: {
            title: 'AI编辑',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/AIresume/AiEdit.vue'),
    },
    {
        name: "aiReaume",
        path: '/Airesume',
        meta: {
            title: 'AI编辑',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/AIresume/Airesume.vue'),
    },
    {
        name: "aiCreate",
        path: '/aiCreate',
        meta: {
            title: 'AI生成',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/AIresume/Aicreate.vue'),
    },
    {
        name: "hld",
        path: '/hld',
        meta: {
            title: '兴趣测试',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/hldExplain.vue'),
    },
    {
        name: "hldProblem",
        path: '/hldProblem',
        meta: {
            title: '兴趣测试题目',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/hollandProblem.vue'),
    },
    {
        name: "hldResult",
        path: '/hldResult',
        meta: {
            title: '兴趣测试结果',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/hldResults.vue'),
    },
    {
        name: "mbti",
        path: '/mbti',
        meta: {
            title: '性格测试',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/mbtiExplain.vue'),
    },
    {
        name: "mbtiProblem",
        path: '/mbtiProblem',
        meta: {
            title: '性格测试问题',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/MBTIProblem.vue'),
    },
    {
        name: "mbtiResult",
        path: '/mbtiResult',
        meta: {
            title: '性格测试结果',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/mbtiResults.vue'),
    },
    {
        name: "testHistory",
        path: '/testHistory',
        meta: {
            title: '历史记录',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/evaluation/history.vue'),
    },
    {
        name: "mateJob",
        path: '/mateJob',
        meta: {
            title: '岗位匹配',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/job/index.vue'),
    },
    {
        name: "buy",
        path: '/buy',
        meta: {
            title: '购买详情',
            keepAlive: false,
            auth: true,
        },
        component: () => import('@/views/buy/index.vue'),
    },
    {
        name: "NotFont",
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/404/index.vue'),
        alias: '/404', // 别名
        hideMenu: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    //获取到本地的session_id
    const {
        session_id
    } = useUserStore(pinia)

    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }

    //判断该路由是否需要登录权限
    //to.meta.auth是获取到该请求中携带的该参数
    if (to.meta.auth) {
        //判断当前的session_id是否存在，也就是登录时的session_id
        if (session_id && session_id !== ('undefined' || '' || 'null')) {
            next()
        } else {
            //如果session_id不存在，前往登录
            next({
                path: '/login'
            })
        }
    } else {
        //如果不需要登录认证，就直接访问
        //如果指向的是登录页面，有session_id信息则指向个人中心
        // if (to.path === "/login" && session_id !== ('undefined' || '' || null)) {
        if (to.path === "/login" && from.path !== '/my') {
            console.log(session_id !== null)
            if (session_id !== 'undefined' && session_id !== '' && session_id !== null) {
                next({
                    path: '/my',
                })
            } else {
                next()
            }
        } else {
            next()
        }
    }
})

export default router